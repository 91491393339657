class FlexibleTabs {
	constructor() {
		this.groupIndex = 0;
		this.frequencyIndex = 0;

		document.addEventListener('DOMContentLoaded', () => {
			this.initAllTabs();
			this.addEventListeners();
			this.activateDefaultDonation(0);
		});
	}

	activateDefaultDonation(index) {
		const value = index === 0 ? 25 : 100;
		document
			.querySelectorAll('input[name="wc_label_price"]')
			.forEach((radio) => {
				radio.parentElement.classList.toggle('wc-active', radio.value == value);
				if (radio.value == value) radio.click();
			});
	}

	initAllTabs() {
		// Show the first group by default
		this.showGroupTab(0);

		// Initialize all tab containers for each group
		document.querySelectorAll('.tab-container').forEach((tabContainer) => {
			const tabHeaders = tabContainer.querySelectorAll('.tab-header.initial');
			const hmContent = document.querySelectorAll(
				'.tab-container.honoured-container .tab-content'
			);

			hmContent[1].style.display = 'block';
			hmContent[1].classList.add('open');
			tabHeaders[0].classList.add('open');
			this.showGroupContent(0);
			this.showFrequncyContent(0);
		});
	}

	addEventListeners() {
		// Event delegation for group tabs
		if (document.querySelector('.tab-container.group-container')) {
			document
				.querySelector('.tab-container.group-container')
				.addEventListener('click', (event) => {
					if (event.target.closest('.tab-header')) {
						let groupIndex = event.target.closest('.tab-header').dataset.group;
						this.handleGroupTabClick(event);
						this.showGroupContent(groupIndex);
						this.groupIndex = groupIndex;
					}
				});
		}

		// Event delegation for frequency tabs
		if (document.querySelector('.tab-container.frequency-container')) {
			document
				.querySelector('.tab-container.frequency-container')
				.addEventListener('click', (event) => {
					if (event.target.closest('.tab-header')) {
						let frequencyIndex =
							event.target.closest('.tab-header').dataset.frequency;
						this.handleFrequencyTabClick(event);
						this.showFrequncyContent(frequencyIndex);
						this.frequencyIndex = frequencyIndex;
					}
				});
		}
		// Event delegation for honoured memember tab

		if (document.querySelector('.tab-container.honoured-container')) {
			document
				.querySelector('.tab-container.honoured-container')
				.addEventListener('click', (event) => {
					if (event.target.closest('.tab-header')) {
						document
							.querySelectorAll('.tab-container.honoured-container .tab-header')
							.forEach((tabHeader) => {
								tabHeader.classList.remove('open');
								tabHeader.nextElementSibling.classList.remove('open');
								tabHeader.nextElementSibling.style.display = 'none';
							});
						event.target.closest('.tab-header').classList.toggle('open');
						event.target.nextElementSibling.classList.toggle('open');
						event.target.nextElementSibling.style.display = 'block';
					}
				});
		}
	}

	handleGroupTabClick(event) {
		const groupIndex = event.target.closest('.tab-header').dataset.group;
		this.showGroupTab(groupIndex);
	}

	handleFrequencyTabClick(event) {
		const frequencyIndex =
			event.target.closest('.tab-header').dataset.frequency;
		this.showDonationTab(this.groupIndex, frequencyIndex);
	}

	showGroupTab(groupIndex) {
		document
			.querySelectorAll('.donation-container')
			.forEach((groupContainer) => {
				groupContainer.style.display =
					groupContainer.dataset.dontaionGroup == groupIndex ? 'block' : 'none';
			});
		this.showDonationTab(groupIndex, this.frequencyIndex);
	}

	showDonationTab(groupIndex, donationIndex) {
		document
			.querySelectorAll(
				`.donation-container[data-dontaion-group="${groupIndex}"] .donation-tab`
			)
			.forEach((tab) => {
				tab.style.display =
					tab.dataset.donation == donationIndex ? 'block' : 'none';
			});
	}

	showGroupContent(groupIndex) {
		document
			.querySelectorAll('.tab-content[data-group]')
			.forEach((tabContent) => {
				tabContent.style.display = 'none';
			});
		document
			.querySelectorAll('.tab-header[data-group]')
			.forEach((tabHeader) => {
				tabHeader.classList.remove('open');
			});

		document
			.querySelector('.tab-header[data-group="' + groupIndex + '"]')
			.classList.add('open');
		document.querySelector(
			'.tab-content[data-group="' + groupIndex + '"]'
		).style.display = 'block';
	}

	showFrequncyContent(frequencyIndex) {
		document
			.querySelectorAll('.tab-content[data-frequency]')
			.forEach((tabContent) => {
				tabContent.style.display = 'none';
			});
		document
			.querySelectorAll('.tab-header[data-frequency]')
			.forEach((tabHeader) => {
				tabHeader.classList.remove('open');
			});

		document
			.querySelector('.tab-header[data-frequency="' + frequencyIndex + '"]')
			.classList.add('open');
		document.querySelector(
			'.tab-content[data-frequency="' + frequencyIndex + '"]'
		).style.display = 'block';
	}
}

export default FlexibleTabs;
